import {useState} from "react";

import {InputAdornment, IconButton, TextFieldProps} from "@mui/material";

import StandartTextField from "./StandartTextField";

import {styled} from "@mui/material/styles";
import EyeCloseIcon from "@assets/login/EyeCloseIcon";
import EyeOpenIcon from "@assets/login/EyeOpenIcon";
import {Tooltip} from "@mui/material";

export type PasswordTextFieldProps = {
    iconColor: string;
} & TextFieldProps;

const PasswordTextField = styled(
    ({iconColor, ...props}: PasswordTextFieldProps) => {
        const [showPassword, setShowPassword] = useState<boolean>(false);

        const handleClickShowPassword = () => {
            setShowPassword((showPassword) => !showPassword);
        };

        return (
            <>
                <StandartTextField
                    type={showPassword ? "text" : "password"}
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}

                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showPassword ? (
                                        <Tooltip title="Скрыть пароль">
                                            <IconButton
                                                aria-label="Hide password"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                <EyeCloseIcon color={iconColor}/>
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Показать пароль">
                                            <IconButton
                                                aria-label="Show password"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                <EyeOpenIcon color={iconColor}/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </InputAdornment>
                            ),
                        }
                    }}

                    {...props}
                />
            </>
        );
    }
)(({theme}) => ({}));

export default PasswordTextField;
