export enum Roles {
  Driver = "10",
  Medical = "20",
  Supervisor = "50",
  Admin = "100",
}
export enum TableInfo {
  Admin = "Admin",
  Supervisor = "Supervisor",
  DriverAdmin = "DriverAdminPanel",
  DriverOrg = "DriverOrgPanel",
  MedicalAdmin = "MedicalAdminPanel",
  MedicalOrg = "MedicalOrgPanel",
}

export enum TranslatedRoles {
  "driver" = "Водитель",
  "medicalworker" = "Медицинский работник",
  "supervisor" = "Руководитель",
  "root" = "Администратор",
}
