import {Box, BoxProps, Typography} from "@mui/material";

import StyledTableHeader from "./style";

type TableHeaderProps = {
    rows: FoundationApp.TableHeaderProps;
} & BoxProps;

const TableHeader: React.FC<TableHeaderProps> = ({rows, ...props}) => {
    const headers: { gridArea: string; headerName: string }[] = [];

    for (const key in rows) {
        if (rows[key].hidden) {
            continue;
        }
        headers.push({
            headerName: rows[key].headerName,
            gridArea: rows[key].gridArea,
        });
    }

    return (
        <StyledTableHeader rows={rows} {...props}>
            {headers.map((row, idx) => (
                <Box id={row.gridArea} key={idx} sx={{gridArea: row.gridArea}}>
                    <Typography component="h4" variant="h4">
                        {row.headerName}
                    </Typography>
                </Box>
            ))}
        </StyledTableHeader>
    );
};

export default TableHeader;
