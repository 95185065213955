import { ProfileErrors } from "@constants/notifications/errors";

import { notificationBuilder } from "@utils/notificationBuilder";

import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "@services/users/users_service";

import {
  ProfileInfo,
  CurrentProfile,
  ProfileEditArguments,
} from "@app-types/profile.model";

import { ProfileByIdForEditAdminPanel } from "@app-types/users.model";
import { ProfileSuccess } from "@constants/notifications/success";

// AdminPanel
/**
 * Получение профиля пользователя по ID для редактирования в АДМИНСКОЙ ПАНЕЛИ
 */
export const getProfileByIdAdminPanel = createAsyncThunk<
  ProfileByIdForEditAdminPanel,
  string,
  FoundationApp.AsyncThunkConfig<boolean>
>("profile/getProfileByIdAdminPanel", async (userId, thunkAPI) => {
  try {
    const data = await UserService.getProfileByIdAdminPanel(userId);
    return data;
  } catch (error) {
    notificationBuilder(ProfileErrors.GET_PROFILE_BY_ID, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Редактирование собственного профиля в ProfileSettings АДМИНОМ
 */

/**
 * Получение профиля текущего авторизованного пользователя
 */
export const getProfileInfo = createAsyncThunk<
  ProfileInfo,
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("profile/getProfileInfo", async (_, thunkAPI) => {
  try {
    return await UserService.getProfileInfo();
  } catch (error) {
    // notificationBuilder(ProfileErrors.GET_PROFILE_INFO, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение профиля для редактирования текущего пользователя
 */
export const getProfileForEdit = createAsyncThunk<
  CurrentProfile,
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("profile/getProfileForEdit", async (_, thunkAPI) => {
  try {
    return await UserService.getProfileForEdit();
  } catch (error) {
    notificationBuilder(ProfileErrors.GET_PROFILE_FOR_EDIT, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение профиля для редактирования по ID вне админской учетки
 */
export const getProfileByIdOrgPanel = createAsyncThunk<
  CurrentProfile,
  string,
  FoundationApp.AsyncThunkConfig<boolean>
>("profile/getProfileByIdOrgPanel", async (userId, thunkAPI) => {
  try {
    return await UserService.getProfileByIdOrgPanel(userId);
  } catch (error) {
    notificationBuilder(ProfileErrors.GET_PROFILE_BY_ID, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Редактирование собственного профиля в ProfileSettings
 *
 * Имеет одни и те же параметры и URL как для editOrgUsers
 */
export const editProfile = createAsyncThunk<
  Omit<ProfileEditArguments, "id" | "job" | "personnelNumber" | "companyId">,
  ProfileEditArguments,
  FoundationApp.AsyncThunkConfig<boolean>
>("profile/editProfile", async (editProfileInfo, thunkAPI) => {
  try {
    await UserService.editProfile(editProfileInfo);

    notificationBuilder(ProfileSuccess.EDIT_PROFILE, "success", thunkAPI);

    return {
      ...editProfileInfo,
    };
  } catch (error) {
    notificationBuilder(ProfileErrors.EDIT_PROFILE, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

export const addPhoto = createAsyncThunk<
  ProfileInfo,
  FormData,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/addPhoto", async (photo, thunkAPI) => {
  try {
    await UserService.addPhoto(photo);
    const data = await UserService.getProfileInfo();

    notificationBuilder(ProfileSuccess.ADD_PHOTO, "success", thunkAPI);

    return data;
  } catch (error) {
    notificationBuilder(ProfileErrors.ADD_PHOTO, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

export const deletePhoto = createAsyncThunk<
  ProfileInfo,
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/deletePhoto", async (_, thunkAPI) => {
  try {
    await UserService.deletePhoto();
    const data = await UserService.getProfileInfo();

    notificationBuilder(ProfileSuccess.DELETE_PHOTO, "success", thunkAPI);

    return data;
  } catch (error) {
    notificationBuilder(ProfileErrors.DELETE_PHOTO, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});
