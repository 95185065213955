import Cookies from "js-cookie";

class TokenService {
  setSession() {
    Cookies.set("session", new Date().toDateString());
  }
  
  getSession() {
    return Cookies.get("session");
  }
  
  removeSession() {
    Cookies.remove("session");
  }
}

export default new TokenService();
