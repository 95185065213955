export enum AuthErrors {
  LOGIN = "Неправильный логин или пароль",
}
export enum DevicesErrors {
  GET_DEVICES = "Ошибка при получении устройств",
  ADD_NEW_DEVICE = "Ошибка при добавления устройств",
  EDIT_DEVICE = "Ошибка при редактировании устройства",
  DELETE_DEVICE = "Ошибка при удалении устройства",
  GET_DEVICE_TYPES = "Ошибка при получении типов устройств",
  ADD_NEW_DEVICE_TYPE = "Новый тип устройства загружен",
  EDIT_DEVICE_TYPE = "Тип устройства успешно отредактирован",
  DELETE_DEVICE_TYPE = "Ошибка при удалении типа устройства",
}
export enum EventsErrors {
  GET_EVENTS = "Ошибка при получении событий",
  GET_EVENTS_TYPE = "Ошибка при получении типов событий",
}
export enum PretripErrors {
  GET_PRETRIPLIST = "Ошибка при получении пред-рейсовых данных",
  GET_PRETRIPCARDS = "Ошибка при получении пред-рейсовых данных",
  GET_USERS_LAST_CARD_BY_ID = "Ошибка при получении последней анкеты пользователя",
  SEND_COMMENT = "Ошибка при отправке сообщения",
  SEND_STATUS = "Ошибка при обновлении статуса",
  GET_USER_STATISTIC = "Ошибка при получении статистики",
}
export enum ProfileErrors {
  GET_PROFILE_BY_ID = "Ошибка при получении профиля",
  GET_PROFILE_INFO = "Ошибка получения информации о профиле",
  GET_PROFILE_FOR_EDIT = "Ошибка при получении профиля для редактирования",
  EDIT_PROFILE = "Ошибка при редактирования профиля",
  ADD_PHOTO = "Ошибка при добавлении фотографии",
  DELETE_PHOTO = "Ошибка при удалении фотографии",
  TYPE_ERROR = "Неверный тип изображения",
  SIZE_ERROR = "Размер не должен превышать больше 2 Мб",
}
export enum UsersErrors {
  GET_USERS = "Ошибка при получении пользователей",
  EDIT_USERS = "Ошибка при редактировании пользователя",
  ADD_NEW_USER = "Ошибка при добавлении пользователя",
  DELETE_USER = "Ошибка при удалении пользователя",
  GET_COMPANIES = "Ошибка при получении списка всех компаний",
  ADD_NEW_COMPANY = "Ошибка при создании компании",
  EDIT_COMPANY = "Ошибка при редактировании компании",
  DELETE_COMPANY = "Ошибка при удалении компании",
  GET_COMPANY_LIST = "Ошибка при получении списка компании",
  GET_ROLES = "Ошибка при получении списка ролей",
  GET_JOBS_LIST = "Ошибка при получении должностей",
  ADD_NEW_JOB = "Ошибка при создании должности",
  EDIT_JOB = "Ошибка при обновлении должности",
  DELETE_JOB = "Ошибка при удалении должности",
}

