export enum DevicesSuccess {
  ADD_NEW_DEVICE = "Новое устройство загружено",
  EDIT_DEVICE = "Устройство отредактировано",
  DELETE_DEVICE = "Устройство удалено",
  ADD_NEW_DEVICE_TYPE = "Новый тип устройства загружен",
  EDIT_DEVICE_TYPE = "Тип устройства успешно отредактирован",
  DELETE_DEVICE_TYPE = "Тип устройства удален",
}
export enum PretripSuccess {
  SEND_COMMENT = "Сообщение отправлено",
  SEND_STATUS_TRUE = "Рейс одобрен",
  SEND_STATUS_FALSE = "Рейс запрещен",
}
export enum ProfileSuccess {
  EDIT_PROFILE = "Настройки пользователя обновлены",
  ADD_PHOTO = "Фотография успешно загружена",
  DELETE_PHOTO = "Фотография успешно удалена",
}
export enum UsersSuccess {
  EDIT_USERS = "Настройки пользователя обновлены",
  ADD_NEW_USER = "Пользователь добавлен",
  DELETE_USER = "Пользователь удален",
  ADD_NEW_JOB = "Должность добавлена",
  EDIT_JOB = "Должность обновлена",
  DELETE_JOB = "Должность удалена",
  ADD_NEW_COMPANY = "Данные об организации успешно добавлены",
  EDIT_COMPANY = "Данные об организации успешно обновлены",
  DELETE_COMPANY = "Данные об организации успешно удалены",
}
