import { PretripErrors } from "@constants/notifications/errors";
import { PretripSuccess } from "@constants/notifications/success";
import { notificationBuilder } from "@utils/notificationBuilder";

import { createAsyncThunk } from "@reduxjs/toolkit";
import PreTripService from "@services/preTrip/preTrip_service";

import {
  PretripList,
  PretripCardList,
  PreTripUserStatistic,
  PreTripUsersLastCard,
  PreTripUpdateStatus,
  PreTripUpdateComment,
  PretripDriverCardList,
} from "@app-types/preTrip.model";

//-------------------------- PreTrip-------------------------

/**
 * Получение пред-/пост-рейсовых данных
 */
export const getPreTripList = createAsyncThunk<
  PretripList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/getPreTripList", async (searchArguments, thunkAPI) => {
  try {
    return await PreTripService.getPreTripList(searchArguments);
  } catch (error) {
    notificationBuilder(PretripErrors.GET_PRETRIPLIST, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение пред-/пост-рейсовых данных для конкретных организаций
 */
export const getPreTripCards = createAsyncThunk<
  PretripCardList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/getPreTripCards", async (searchArguments, thunkAPI) => {
  try {
    return await PreTripService.getPreTripCards(searchArguments);
  } catch (error) {
    notificationBuilder(PretripErrors.GET_PRETRIPCARDS, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение пред-/пост-рейсовых данных для водителей
 */
export const getPreTripDriverCards = createAsyncThunk<
  PretripDriverCardList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/getPreTripDriverCards", async (searchArguments, thunkAPI) => {
  try {
    return await PreTripService.getPreTripDriverCards(searchArguments);
  } catch (error) {
    notificationBuilder(PretripErrors.GET_PRETRIPCARDS, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение последней анкеты конкретного пользователя
 */

export const getUsersLastCardById = createAsyncThunk<
  PreTripUsersLastCard,
  string,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/getUsersLastCardById", async (userId, thunkAPI) => {
  try {
    return await PreTripService.getUsersLastCardById(userId);
  } catch (error) {
    notificationBuilder(
      PretripErrors.GET_USERS_LAST_CARD_BY_ID,
      "error",
      thunkAPI
    );
    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- PreTripHub-------------------------

/**
 * отправить рекомендации фельдшера
 */
export const sendComment = createAsyncThunk<
  PreTripUpdateComment,
  PreTripUpdateComment,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/sendComment", async ({ id, comment }, thunkAPI) => {
  try {
    await PreTripService.sendComment({ id, comment });

    notificationBuilder(PretripSuccess.SEND_COMMENT, "success", thunkAPI);

    return {
      id,
      comment,
    };
  } catch (error) {
    notificationBuilder(PretripErrors.SEND_COMMENT, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * отправить новый статус
 */

export const sendStatus = createAsyncThunk<
  PreTripUpdateStatus,
  PreTripUpdateStatus,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/sendStatus", async ({ id, status, comment }, thunkAPI) => {
  try {
    if (comment !== "") {
      await PreTripService.sendComment({
        id,
        comment,
      });
    }

    await PreTripService.sendStatus({ id, status });

    notificationBuilder(
      status
        ? PretripSuccess.SEND_STATUS_TRUE
        : PretripSuccess.SEND_STATUS_FALSE,
      "success",
      thunkAPI
    );

    return {
      id,
      status,
      comment,
    };
  } catch (error) {
    notificationBuilder(PretripErrors.SEND_STATUS, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- Statistic-------------------------

/**
 * Получение статистики конкретного пользователя
 */

export const getUserStatistic = createAsyncThunk<
  PreTripUserStatistic,
  number,
  FoundationApp.AsyncThunkConfig<boolean>
>("preTrip/getUserStatistic", async (userId, thunkAPI) => {
  try {
    return await PreTripService.getUserStatistic(userId);
  } catch (error) {
    notificationBuilder(PretripErrors.GET_USER_STATISTIC, "error", thunkAPI);
    return thunkAPI.rejectWithValue(false);
  }
});
