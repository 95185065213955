import NavLink from "../Navlink";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import ProfileIcon from "@assets/Sidebar/ProfileIcon";

const SettingsItem = () => {
  return (
    <>
      <NavLink to="/settings">
        {(isActive) => (
          <ListItemButton
            disableRipple
            disableGutters
            sx={{ marginLeft: "3.8rem" }}
          >
            <ListItemIcon>
              <ProfileIcon isActive={isActive} />
            </ListItemIcon>
            <ListItemText
              primary="Профиль"
              slotProps={{
                  primary: {
                      variant: "body2",
                      fontWeight: "700",
                  }
              }}
            />
          </ListItemButton>
        )}
      </NavLink>
    </>
  );
};

export default SettingsItem;
