import {Components, Theme} from "@mui/material/styles";

const Input = (theme: Theme): Components<Theme> => {
    return {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    "::placeholder": {
                        opacity: 1,
                    },
                    color: theme.palette.input.textColor,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                adornedEnd: {
                    border: `1px solid ${theme.palette.input.adornedEnd.border}`,
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {},
            },
            defaultProps: {
                slotProps: {
                    inputLabel: {
                        color: "labelPrimary",
                    }
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "2.5rem",
                    background: theme.palette.outlineInput.bg,
                },
                input: {
                    padding: "1rem 2rem",
                    color: theme.palette.outlineInput.color,

                    "&:hover": {
                        border: 0,
                    },
                },
                notchedOutline: {
                    border: "0 !important",
                },
                error: {
                    color: "red !important",
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.inputLabel.color,
                },
            },
        },
    };
};

export default Input;
