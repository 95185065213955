import {StyledTableFooter, StyledTablePaginationContainer} from "./style";

import {
    Box,
    Pagination,
    PaginationItem,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";

import {ChevronLeftIcon, ChevronRightIcon} from "../../../assets/IconsSVG";

import {PaginationSelect} from "@components";

type TableFooterProps = {
    count: number;
    pageSize: number;
    pageNumber: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    handlePageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TableFooter: React.FC<TableFooterProps> = ({
                                                     count,
                                                     pageSize,
                                                     pageNumber,
                                                     handlePageChange,
                                                     handlePageSizeChange,
                                                 }) => {
    const theme = useTheme();

    return (
        <StyledTableFooter>
            <StyledTablePaginationContainer>
                <Pagination
                    renderItem={(item) => (
                        <PaginationItem
                            slots={{
                                previous: () => (
                                    <Tooltip title="Назад">
                                        <Box>
                                            <ChevronLeftIcon
                                                color={theme.palette.pagination.item.iconColor}
                                            />
                                        </Box>
                                    </Tooltip>
                                ),
                                next: () => (
                                    <Tooltip title="Вперед">
                                        <Box>
                                            <ChevronRightIcon
                                                color={theme.palette.pagination.item.iconColor}
                                            />
                                        </Box>
                                    </Tooltip>
                                ),
                            }}
                            {...item}
                        />
                    )}
                    page={pageNumber}
                    count={count}
                    siblingCount={0}
                    onChange={handlePageChange}
                />
                <Box>
                    <Typography mr={5} variant="body3">
                        Строк на странице:
                    </Typography>
                    <PaginationSelect
                        onChange={handlePageSizeChange}
                        currentPageSize={pageSize}
                    />
                </Box>
            </StyledTablePaginationContainer>
        </StyledTableFooter>
    );
};

export default TableFooter;
